import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import BlocksRenderer from "../components/blocks-renderer";
import Seo from "../components/Seo";
import Sidebar from "../components/Sidebar";

const ArticlePage = ({ data }) => {
  const article = data.strapiArticle;

  const seo = {
    metaTitle: article.seo_title || article.title,
    metaDescription: article.seo_description || article.description,
    shareImage: article.cover,
  };

  return (
    <Layout as="article">
      <Seo seo={seo} />
      <div className="container grid-cols-4 gap-2 lg:grid">
        <div className="col-span-3">
          <header className="max-w-4xl py-8">
            <h1 className="text-6xl font-bold text-neutral-700">
              {article.title}
            </h1>
            <p className="mt-4 text-2xl text-neutral-500">
              {article.description}
            </p>
            <GatsbyImage
              image={getImage(article?.cover?.localFile)}
              alt={article?.cover?.alternativeText}
              className="mt-6"
            />
          </header>
          <main className="mt-2">
            <BlocksRenderer blocks={article.blocks || []} />
          </main>
        </div>
        <div className="mt-10">
          <Sidebar />
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query ($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      id
      slug
      title
      description
      blocks {
        ...Blocks
      }
      cover {
        alternativeText
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      seo_description
      seo_title
    }
  }
`;

export default ArticlePage;
