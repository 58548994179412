import React from "react";

const BlockRichText = ({ data }) => {
  return (
    <div className="prose mr-5 py-8">
      <div
        dangerouslySetInnerHTML={{
          __html: data.richTextBody.data.childMarkdownRemark.html,
        }}
      />
    </div>
  );
};

export default BlockRichText;
