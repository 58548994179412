import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import JotformEmbed from "react-jotform-embed";

const menuCats = [
  { nombre: "Bebidas con Tequila", href: "/category/bebidas-con-tequila" },
  { nombre: "Bebidas con Vodka", href: "/category/bebidas-con-vodka" },
  { nombre: "Bebidas con Ron", href: "/category/bebidas-con-ron" },
  { nombre: "Bebidas con Whisky", href: "/category/bebidas-con-whisky" },
];

function Sidebar() {
  return (
    <aside className="mt-20">
      <section className="mb-5">
        <h6 className="mb-3 border-b-4 border-exoticberry text-xl uppercase text-neutral-700">
          Descargar Las Recetas
        </h6>
        <div className="text-center text-neutral-500">
          <div className="prose-sm">
            <p>
              Para obtener nuestro refrescante recetario, solo tienes que
              decirnos a dónde enviarte las combinaciones que Boone’s tiene para
              ti.
            </p>
          </div>
        </div>
        <div>
          <div className="h-auto w-full">
            <StaticImage
              src="../assets/images/recetario.png"
              alt="Cocteles con Boones"
              placeholder="dominantColor"
              layout="fullWidth"
            />
          </div>
          <div className="w-full text-center">
            <JotformEmbed src="https://form.jotform.com/211386093973867" />
          </div>
        </div>
      </section>
      <section className="mx-5 py-2 text-lg capitalize">
        <h6 className="mb-3 border-b-4 border-exoticberry text-xl uppercase text-neutral-700">
          Categorías Boone´s
        </h6>
        {menuCats.map((menu) => (
          <Link
            className="block py-2 text-neutral-500"
            key={menu.nombre}
            to={menu.href}
          >
            {menu.nombre}
          </Link>
        ))}
      </section>
    </aside>
  );
}

export default Sidebar;
